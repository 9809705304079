import axios from '@/plugins/axios';

class AntifraudService {
  getQueued(data) {
    this.data = data;
    return axios().get('admin/antifraud/queued', { params: this.data }).then((response) => response.data);
  }

  getAntifraudMessages(data) {
    this.data = data;
    return axios().get('admin/antifraud/messages', { params: this.data }).then((response) => response.data);
  }

  approveMessage(messageId) {
    this.messageId = messageId;
    return axios().put(`admin/antifraud/queued/${messageId}/approve`);
  }

  approveUrls(messageId) {
    this.messageId = messageId;
    return axios().put(`admin/antifraud/queued/${messageId}/approve-urls`);
  }

  disapproveMessage(messageId) {
    this.messageId = messageId;
    return axios().put(`admin/antifraud/queued/${messageId}/disapprove`);
  }

  createWord(data) {
    this.data = data;
    return axios().post('admin/antifraud/words', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  getAntifraudWords(data) {
    this.data = data;
    return axios().get('admin/antifraud/words', { params: this.data }).then((response) => response.data);
  }

  getUrls(data) {
    this.data = data;
    return axios().get('admin/antifraud/urls', { params: this.data }).then((response) => response.data);
  }

  createUrl(data) {
    this.data = data;
    return axios().post('admin/antifraud/urls', this.data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteWord(wordId) {
    this.wordId = wordId;
    return axios().delete(`admin/antifraud/words/${wordId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteUrl(urlId) {
    this.urlId = urlId;
    return axios().delete(`admin/antifraud/urls/${urlId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteMessage(messageId) {
    this.messageId = messageId;
    return axios().delete(`admin/antifraud/messages/${messageId}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }
}

export default new AntifraudService();
